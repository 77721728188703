<template>
    <div>
        <div class="row mb-3">
            <label class="col-label form-label required"
                >{{ $t('payRollCycleDate.CycleDateName') }}
            </label>
            <div class="col-md-12">
                <input
                    type="text"
                    v-model="model.pc_date_name"
                    class="form-control"
                    :class="{
                        'is-invalid': errors.has('pc_date_name')
                    }"
                />
                <div class="invalid-feedback" v-if="errors.has('pc_date_name')">
                    {{ errors.first('pc_date_name') }}
                </div>
            </div>
        </div>
        <!-- <div class="row mb-3">
            <div class="col-md-12">
                <label class="form-label col-label  mt-2">{{ $t("payRollCycleDate.firstCycleCalculationCay") }}</label>
                <Select v-model="model.first_cycle_calculation_day" :class="{
                    'is-invalid': errors.has('first_cycle_calculation_day')
                }">

                    <Option v-for="(payRollCycleDate, index) in payRollCycleDate" :value="payRollCycleDate.value"
                        :key="index">{{ payRollCycleDate.label }}</Option>
                </Select>
                <div class="invalid-feedback" v-if="errors.has('pc_date_name')">
                    {{ errors.first('first_cycle_calculation_day') }}
                </div>
            </div>
        </div> -->
        <div>
            <Checkbox
                v-model="model.monthly_based"
                @on-change="() => (model.day_from = model.day_to = null)" >{{ $t('payRollCycleDate.Monthly') }}</Checkbox>
        </div>
        <div class="row">
            <div class="col-md-6">
                <label
                    class="form-label col-label mt-2"
                    :class="{ required: !model.monthly_based }"
                    >{{ $t('payRollCycleDate.DayFrom') }}</label
                >
                <Select
                    v-model="model.day_from"
                    :disabled="model.monthly_based"
                    :class="{
                        'is-invalid': errors.has('day_from')
                    }"
                >
                    <Option
                        v-for="(payRollCycleDate, index) in payRollCycleDate"
                        :value="payRollCycleDate.value"
                        :key="index"
                        >{{ payRollCycleDate.label }}</Option>
                </Select>
                <div class="invalid-feedback" v-if="errors.has('pc_date_name')">
                    {{ errors.first('day_from') }}
                </div>
            </div>
            <div class="col-md-6">
                <label
                    class="form-label col-label mt-2"
                    :class="{ required: !model.monthly_based }"
                >
                    {{ $t('payRollCycleDate.DayTO') }}</label
                >
                <Select
                    v-model="model.day_to"
                    :disabled="model.monthly_based"
                    :class="{
                        'is-invalid': errors.has('day_to')
                    }"
                >
                    <Option
                        v-for="(payRollCycleDate, index) in payRollCycleDate"
                        :value="payRollCycleDate.value"
                        :key="index"
                        >{{ payRollCycleDate.label }}</Option>
                </Select>
                <div class="invalid-feedback" v-if="errors.has('day_to')">
                    {{ errors.first('day_to') }}
                </div>
            </div>
        </div>
        <div class="mt-3">
            <Checkbox
                v-model="model.is_enable_first_cycle"
                @on-change="() => (model.first_cycle_calculation_day = 0)">{{
                    $t('payRollCycleDate.enableFirstCycleCalculate')
                }}</Checkbox>
        </div>
        <div class="row mb-3">
            <div class="col-md-12">
                <label
                    class="form-label col-label mt-2"
                    :class="{ required: model.is_enable_first_cycle }"
                    >{{
                        $t('payRollCycleDate.firstCycleCalculationCay')
                    }}</label
                >
                <Select
                    v-model.number="model.first_cycle_calculation_day"
                    :disabled="model.is_enable_first_cycle"
                    :class="{
                        'is-invalid': errors.has('first_cycle_calculation_day')
                    }"
                >
                    <Option
                        v-for="(payRollCycleDate, index) in payRollCycleDate"
                        :value="payRollCycleDate.value"
                        :key="index"
                        >{{ payRollCycleDate.label }}</Option>
                </Select>
                <div
                    class="invalid-feedback"
                    v-if="errors.has('first_cycle_calculation_day')"
                >
                    {{ errors.first('first_cycle_calculation_day') }}
                </div>
            </div>
        </div>
        <div class="row">
            <label class="form-label col-label required">{{
                $t('payRollCycleDate.lateJoinBackPayDay')
            }}</label>
            <Select
                v-model="model.late_join_back_pay_day"
                :class="{
                    'is-invalid': errors.has('late_join_back_pay_day')
                }"
            >
                <Option
                    v-for="(payRollCycleDate, index) in payRollCycleDate"
                    :value="payRollCycleDate.value"
                    :key="index">{{ payRollCycleDate.label }}</Option>
            </Select>
            <div
                class="invalid-feedback"
                v-if="errors.has('late_join_back_pay_day')"
            >
                {{ errors.first('late_join_back_pay_day') }}
            </div>
        </div>
        <div slot="footer">
            <div class="tw-flex tw-justify-end tw-space-x-2 tw-sh mt-3">
                <ts-button
                    @click.prevent="() => $emit('cancel')"
                    class="btn-gray"
                >
                    {{ $t('cancel') }}</ts-button
                >
                <ts-button
                    v-if="!isUpdate"
                    color="primary"
                    outline
                    :waiting="waiting_new"
                    :disabled="waiting_new || waiting"
                    @click.prevent="onSaveAddNew"
                >
                    {{ $t('saveAddNew') }}</ts-button
                >
                <ts-button
                    color="primary"
                    v-if="!isUpdate"
                    :waiting="waiting"
                    :disabled="waiting_new || waiting"
                    @click.prevent="onSave"
                >
                    {{ $t('save') }}</ts-button
                >
                <ts-button
                    v-if="isUpdate"
                    color="primary"
                    @click.prevent="onUpdate"
                    :waiting="waiting"
                >
                    {{ $t('update') }}</ts-button
                >
            </div>
        </div>
    </div>
</template>
<script>
import { Errors } from 'form-backend-validation'
import { isEmpty } from 'lodash'
import { mapState, mapActions } from 'vuex'

export default {
    name: 'payroll-cycle-date-form',
    data () {
        return {
            errors: new Errors(),
            loading: false,
            waiting: false,
            waiting_new: false,
            payRollCycleDate: [
                {
                    value: 1,
                    label: '1'
                },
                {
                    value: 2,
                    label: '2'
                },
                {
                    value: 3,
                    label: '3'
                },
                {
                    value: 4,
                    label: '4'
                },
                {
                    value: 5,
                    label: '5'
                },
                {
                    value: 6,
                    label: '6'
                },
                {
                    value: 7,
                    label: '7'
                },
                {
                    value: 8,
                    label: '8'
                },
                {
                    value: 9,
                    label: '9'
                },
                {
                    value: 10,
                    label: '10'
                },
                {
                    value: 11,
                    label: '11'
                },
                {
                    value: 12,
                    label: '12'
                },
                {
                    value: 13,
                    label: '13'
                },
                {
                    value: 14,
                    label: '14'
                },
                {
                    value: 15,
                    label: '15'
                },
                {
                    value: 16,
                    label: '16'
                },
                {
                    value: 17,
                    label: '17'
                },
                {
                    value: 18,
                    label: '18'
                },
                {
                    value: 19,
                    label: '19'
                },
                {
                    value: 20,
                    label: '20'
                },
                {
                    value: 21,
                    label: '21'
                },
                {
                    value: 22,
                    label: '22'
                },
                {
                    value: 23,
                    label: '23'
                },
                {
                    value: 24,
                    label: '24'
                },
                {
                    value: 25,
                    label: '25'
                },
                {
                    value: 26,
                    label: '26'
                },
                {
                    value: 27,
                    label: '27'
                },
                {
                    value: 28,
                    label: '28'
                },
                {
                    value: 29,
                    label: '29'
                },
                {
                    value: 30,
                    label: '30'
                },
                {
                    value: 31,
                    label: '31'
                }
            ],
            model: {
                is_enable_first_cycle: true,
                pc_date_name: null,
                monthly_based: false,
                day_from: null,
                day_to: null,
                late_join_back_pay_day: null,
                first_cycle_calculation_day: 15
            }
        }
    },
    computed: {
        ...mapState('humanResource/payrollCycleDate', ['edit_data']),
        isUpdate () {
            return !isEmpty(this.edit_data)
        }
    },
    methods: {
        ...mapActions('humanResource/payrollCycleDate', ['find']),
        async fetchResource () {
            await this.find(this.$route.params.id).catch(error => {
                this.notice({ type: 'error', text: error.message })
            })
            this.setEditData()
        },
        async onSave () {
            this.errors = new Errors()
            this.waiting = true
            this.$store
                .dispatch('humanResource/payrollCycleDate/store', this.model)
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? 'success' : 'warning',
                        text: response.message
                    })
                    this.$emit('cancel')
                    this.$emit('fetchData')
                })
                .catch(error => {
                    this.errors = new Errors(error.errors)
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.waiting = false
                })
        },
        async onSaveAddNew () {
            this.errors = new Errors()
            this.waiting_new = true
            this.$store
                .dispatch('humanResource/payrollCycleDate/store', this.model)
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? 'success' : 'warning',
                        text: response.message
                    })
                    this.$emit('fetchData')
                    this.clearInput()
                })
                .catch(error => {
                    this.errors = new Errors(error.errors)
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.waiting_new = false
                })
        },
        onUpdate () {
            this.waiting = true
            this.$store
                .dispatch('humanResource/payrollCycleDate/update', {
                    id: this.edit_data.pc_date_id,
                    data: this.model
                })
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? 'success' : 'warning',
                        text: response.message
                    })
                    this.$emit('cancel')
                    this.$emit('fetchData')
                })
                .catch(error => {
                    this.errors = new Errors(error.errors)
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.waiting = false
                })
        },
        async setEditData () {
            if (!isEmpty(this.edit_data)) {
                this.model.pc_date_name = this.edit_data.pc_date_name
                this.model.monthly_based = this.edit_data.monthly_based
                this.model.day_from = this.edit_data.day_from
                this.model.day_to = this.edit_data.day_to
                this.model.first_cycle_calculation_day =
                    this.edit_data.first_cycle_calculation_day
                this.model.is_enable_first_cycle =
                    this.edit_data.is_enable_first_cycle
                this.model.late_join_back_pay_day =
                    this.edit_data.late_join_back_pay_day
            }
        },
        clearInput () {
            this.errors = new Errors()
            this.model.pc_date_name = null
            this.model.monthly_based = false
            this.model.day_from = null
            this.model.day_to = null
            this.model.first_cycle_calculation_day = 15
            this.model.is_enable_first_cycle = true
            this.model.late_join_back_pay_day = null
        },
        notice (not) {
            this.$Notice[not.type]({
                title: 'PAYROLLCYCLEDATE',
                desc: not.text
            })
        }
    },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            vm.fetchResource()
        })
    }
}
</script>
